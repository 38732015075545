<div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if(validationFailed){
                    <div class="ontario-alert ontario-alert--error">
                        <div class="ontario-alert__header">
                            <div class="ontario-alert__header-icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </div>
                            <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                        </div>
                        <div class="ontario-alert__body">
                            <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                            <ul>
                                @if(controlEmptyError && section.type != 'Custom'){
                                    <li><a href="javascript:{}" (click)="scrollToHazardAnswer('suggestionsControllingHazardLabel')">{{'HazardTemplate.ControlEmptyError' | translate}}</a></li>
                                }
                                @if(controlEmptyError && section.type == 'Custom'){
                                    <li><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.CustomControlEmptyError' | translate}}</a></li>
                                }
                                @if(ownControlMeasuresTooLong){
                                    <li><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 80} }}.</a></li>
                                }
                                @if(xssCheckFailed){
                                    <li><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Step' | translate}}&nbsp;2 {{'Stepper.Of' | translate}}&nbsp;2</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>            
            <h1 class="title">
                {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR }} - {{'HazardTemplate.Control' | translate}}
            </h1>            
            <div class="ontario-padding-bottom-24-!" innerHtml="{{translate.currentLang === 'en' ? section.summary : section.summaryFR}}"></div>
        </div>
    </div>
    @if(section.type != 'Custom'){
        <div class="ontario-row">
            <div class="ontario-columns">
                <fieldset class="ontario-fieldset">
                    <!-- <legend class="ontario-fieldset__legend" id="suggestionsControllingHazardLabel">
                        {{'HazardTemplate.SuggestionsControllingHazard' | translate}}
                    </legend> -->
                    @if(controlEmptyError && section.type != 'Custom'){
                        <div class="ontario-error-messaging" id="field-error" role="alert">
                            <span class="ontario-error-messaging__icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </span>
                            <span class="ontario-error-messaging__content">
                                {{'HazardTemplate.ControlEmptyError' | translate}}
                            </span>
                        </div>
                    }
                    <!-- <div class="ontario-checkboxes">
                        @for(cm of controlMeasures; track cm;) {
                            <div class="ontario-checkboxes__item">
                                <input class="ontario-checkboxes__input" id="checkbox-option-{{cm.priority}}" name="options" type="checkbox" value="cm.answerText" [checked]="selectedControlMeasuresIds.includes(cm.id)" (change)="onCheckboxChange(cm.id)">
                                <label class="ontario-checkboxes__label" for="checkbox-option-{{cm.priority}}">
                                    <span innerHtml="{{translate.currentLang === 'en' ? cm.answerText : cm.answerTextFR}}"></span>
                                </label>
                            </div>    
                        }
                    </div> -->

                    <div class="ontario-table-container ontario-small-12 ontario-medium-12 ontario-large-12">
                        <div>
                            <p><strong>{{'Step2.ActivelyDoing' | translate}}</strong>{{translate.currentLang === 'en' ? ": " : " : "}}{{'Step2.ActivelyDoingDescription' | translate}}</p>
                            <p><strong>{{'Step2.WillConsider' | translate}}</strong>{{translate.currentLang === 'en' ? ": " : " : "}}{{'Step2.WillConsiderDescription' | translate}}</p>
                            <p><strong>{{'Step2.NotDoing' | translate}}</strong>{{translate.currentLang === 'en' ? ": " : " : "}}{{'Step2.NotDoingDescription' | translate}}</p>
                        </div>
                        <div class="ontario-table-scroll--wrapper">
                            <div class="ontario-table-scroll--div"></div>
                        </div>
                        <div class="ontario-table-div">
                            @for (controlMeasures of controlMeasureItems; track controlMeasures;let index = $index) {
                                @if (controlMeasures.length > 0) {
                                    <table tabindex="0" class="control-measure-table">
                                        @switch (index) {
                                            @case (0) {
                                                <caption>
                                                    <h3 class="title">
                                                        {{'HazardTemplate.EliminationAndSubstitution' | translate}}
                                                    </h3>
                                                </caption>
                                            }
                                            @case (1) {
                                                <caption>
                                                    <h3 class="title">
                                                        {{'HazardTemplate.EngineeringControls' | translate}}
                                                    </h3>
                                                </caption>
                                            }
                                            @case (2) {
                                                <caption>
                                                    <h3 class="title">
                                                        {{'HazardTemplate.AdministrativeControls' | translate}}
                                                    </h3>
                                                </caption>
                                            }
                                            @case (3) {
                                                <caption>
                                                    <h3 class="title">
                                                        {{'HazardTemplate.HygieneFacilitiesAndPractices' | translate}}
                                                    </h3>
                                                </caption>
                                            }
                                            @case (4) {
                                                <caption>
                                                    <h3 class="title">
                                                        {{'HazardTemplate.PersonalProtectiveEquipment' | translate}}
                                                    </h3>
                                                </caption>
                                            }
                                            @default {}
                                        }
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    {{'Step2.ControlMeasures' | translate}}
                                                </th>
                                                <th scope="col">
                                                    {{'Step2.ActivelyDoing' | translate}}
                                                </th>
                                                <th scope="col" class="">
                                                    {{'Step2.WillConsider' | translate}}
                                                </th>
                                                <th scope="col" class="">
                                                    {{'Step2.NotDoing' | translate}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for(cm of controlMeasures; track cm;) {
                                            <tr>
                                                <th scope="row">
                                                    <span innerHtml="{{translate.currentLang === 'en' ? cm.answerText : cm.answerTextFR}}"></span>
                                                </th>
                                                
                                                <td>
                                                    <div class="ontario-radios">
                                                        <div class="ontario-radios__item">
                                                            <input class="ontario-radios__input"  [checked]="cm.state.toLocaleLowerCase() === 'doing'" value="Active" name="checkbox-option-{{cm.priority}}" type="radio" id="checkbox-option-{{cm.priority}}" (change)="onCheckboxChange(cm.id,'doing')" >
                                                            <label class="ontario-label ontario-radios__label"></label>                                                    
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="">
                                                    <div class="ontario-radios">
                                                        <div class="ontario-radios__item">
                                                            <input class="ontario-radios__input" [checked]="cm.state.toLocaleLowerCase() === 'willconsider'" value="Later" name="checkbox-option-{{cm.priority}}" type="radio" id="checkbox-option-{{cm.priority}}" (change)="onCheckboxChange(cm.id,'willconsider')">
                                                            <label class="ontario-label ontario-radios__label"></label>    
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="">
                                                    <div class="ontario-radios">
                                                        <div class="ontario-radios__item">
                                                            <input class="ontario-radios__input" [checked]="cm.state.toLocaleLowerCase() === 'notdoing'" value="Later" id="checkbox-option-{{cm.priority}}" name="checkbox-option-{{cm.priority}}" type="radio" (change)="onCheckboxChange(cm.id,'notdoing')">
                                                            <label class="ontario-label ontario-radios__label"></label>
                                                        </div>
                                                    </div>
                                                </td>                                                                            
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                }
                            }
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>
    }
    <div class="ontario-row">
        <div class="ontario-columns">
            <legend  class="ontario-fieldset__legend" id="ownControlMeasuresLabel" for="ownControlMeasures">
                {{'HazardTemplate.OwnControlMeasures' | translate}}
                @if(section.type == 'Custom'){
                    <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                }
            </legend >
            <span>
                {{'HazardTemplate.OwnControlMeasuresSummary' | translate}}
            </span>
            @if(controlEmptyError && section.type == 'Custom'){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HazardTemplate.CustomControlEmptyError' | translate}}.
                    </span>
                </div>
            }
            @if(xssCheckFailed){
                <div class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}
                    </span>
                </div>
            }
            @for(answer of ownControlMeasures; track answer; let i = $index) {
                <div class="input-row">
                    <label for="q1answer{{i}}" class="ontario-label sr-only">Label for Input {{i}}</label>
                    @if(answer.validationFailed && ownControlMeasuresTooLong){
                        <div class="ontario-error-messaging" id="field-error" role="alert">
                            <span class="ontario-error-messaging__icon">
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-alert-error"></use>
                                </svg>
                            </span>
                            <span class="ontario-error-messaging__content">
                                {{'HazardTemplate.EntryTooLong' | translate}}
                            </span>
                        </div>
                    }
                    <div class="dynamic-input-container">
                        <input class="ontario-input" type="text" [(ngModel)]="answer.value" id="q1answer{{i}}" aria-label="Enter a value for question 1" [class.ontario-input__error]="answer.validationFailed || controlEmptyError || xssCheckFailed"/>
                        @if(ownControlMeasures.length > 1){
                            <button class="ontario-button ontario-button--tertiary" (click)="removeAnswer(i)">{{ 'Buttons.Delete' | translate}}</button>
                        }
                    </div>
                </div>
            }
            @if(ownControlMeasures.length < 50){
                <button class="ontario-button ontario-button--tertiary" (click)="addAnswer()">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-add"></use>
                    </svg>{{ 'HazardTemplate.AddControlMeasure' | translate}}
                </button>
            }@else {
                <p>{{ 'General.MaxFieldMessage' | translate}}</p>
            }
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <button (click)="back()" class="ontario-button ontario-button--secondary">
                {{ 'Buttons.Back' | translate}}
            </button>
            <button (click)="saveAndContinue()" class="ontario-button ontario-button--primary">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>  
    </div>
  </div>