<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h1 class="title" >
            <p innerHtml="{{'OHSA.Title' | translate}}"></p>
        </h1>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'OHSA.Instruction' | translate}}"></p>
            <br>
            <ul>
                <li innerHTML="{{'OHSA.InstructionPointOne' | translate}}"></li>
                <li>{{'OHSA.InstructionPointTwo' | translate}}</li>
                <li>{{'OHSA.InstructionPointThree' | translate}}</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHTML="{{'OHSA.PostACopy' | translate}}"></p>
            <p>{{'OHSA.YouMay' | translate}} </p>
            <ol style="list-style-type: decimal; margin-left: 1.125rem; margin-top: 0.6rem;">
                <li innerHtml="{{'OHSA.DownloadLink' | translate}}"></li>
                <li innerHtml="{{'OHSA.OrderLink' | translate}}"></li>
            </ol>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            @if (EnPage) {
                <div class="img-container"><img src="/assets/images/OHSA.png" alt="{{'OHSA.ImageAlt' | translate}}" class="scaled-image"></div>
            }
            @if (FrPage) {
                <div class="img-container"><img src="/assets/images/OHSA_fr.png" alt="{{'OHSA.ImageAlt' | translate}}" class="scaled-image"></div>
            } 
        </div>
    </div>
</div>
