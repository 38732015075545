<div class="ontario-form-group">
    <div class="closeButton">
        <label for="email-input">
            <h5>
                {{'WorkspaceManagement.EnterUserEmail' | translate}}
                <span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </h5>
        </label>
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    @if (emptyEmail) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.EmailBlankError' | translate}}
            </span>
        </div>    
    }
    @if (tooLongEmail) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.EmailTooLongError' | translate}}
            </span>
        </div>    
    }
    @if (xssCheckFailed) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.EmailSanitizeError' | translate}}
            </span>
        </div>            
    }
    @if (invalidEmail) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.EmailInvalidError' | translate}}
            </span>
        </div>    
    }
    @if (userExist) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.UserExistError' | translate}}
            </span>
        </div>    
    }
    @if (isUserInOtherBusiness) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'WorkspaceManagement.UserHaveOwnBusiness' | translate}}
            </span>
        </div>    
    }
    <input type="text" class="ontario-input" id="email-input" [class.ontario-input__error]="emptyEmail || tooLongEmail || xssCheckFailed || invalidEmail || userExist || isUserInOtherBusiness" [(ngModel)]="email" cdkFocusInitial/>
    <button (click)="submitClick()" class="ontario-button ontario-button--primary">
        {{'Buttons.Add' | translate}}
    </button>
    <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
        {{'WorkspaceManagement.Cancel' | translate}}
    </button>
</div>

