<div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR}}
          </h1>          
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <p class="item-top-text" innerHtml="{{translate.currentLang === 'en' ? businessModule.module.summary : businessModule.module.summaryFR}}"></p>
            <br />
            <button class="ontario-button ontario-button--secondary" (click)="back()">
                {{ 'Buttons.Back' | translate}}
            </button>
            <button id=continue class="ontario-button ontario-button--primary" (click)="continue()">
                {{ ((businessModule.dismissed) ? 'Buttons.RestoreAndContinue': 'Buttons.Continue') | translate}}
            </button>
            @if (!businessModule.dismissed && businessModule.module.dismissible) {
                <div>
                    <br />
                    <p>{{ 'Hazardlanding.NotApplicable' | translate }}</p>
                    <button  id=dismiss class="ontario-button ontario-button--tertiary" (click)="openDialog()">
                        {{ 'Buttons.Dismiss' | translate}}
                    </button>
                </div>    
            }
        </div>
    </div>
</div>