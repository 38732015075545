<!-- logged in -->
@if (this.isLoggedIn) {
<div class="ontario-padding-bottom-0-!">
  @if (showProfileCompletedAlert) {
  <div class="ontario-row ontario-row-hero">
    <div class="ontario-alert ontario-alert--success">
      <div class="ontario-alert__header">
        <div class="ontario-alert__header-icon">
          <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
            <use href="#ontario-icon-alert-success"></use>
          </svg>
        </div>
        <h2 class="ontario-alert__header-title ontario-h4">{{'Homepage.AlertHeading' | translate}}</h2>
      </div>
      <div class="ontario-alert__body">
        <p innerHtml="{{'Homepage.AlertSummary' | translate}}"></p>
      </div>
    </div>
  </div>
  }
  <div class="ontario-row ontario-row-hero">
    <div class="ontario-columns top-banner">
      <h1 class="title">
        {{'Homepage.WelcomeBack' | translate}}
      </h1>
      <div class="lead-statement-contanier">
        <p class="ontario-lead-statement">{{'Homepage.TitleDescription' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="ontario-row ontario-row-hero ontario-row-hero-grid">
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt=""
        src="/assets/images/homepage-workplace.png">
    </div>
    <div class="ontario-columns vertical-img-container">
      <img class="vertical-img" alt=""
        src="/assets/images/Icons_arrows_01.svg">
    </div>
    <div class="ontario-columns horizontal-img-container">
      <img class="horizontal-img" alt=""
        src="/assets/images/homepage-horizontal.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text">
        <strong class="ontario-badge ontario-badge--neutral-light">{{'Homepage.ProfileUpdated' | translate}}</strong>
        <h2>{{'Homepage.TellUsOfWorkTitle' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.TellUsOfWorkDescription1' | translate}} {{ this.lastUpdatedOn| date: this.translate.currentLang === 'fr' ? 'dd-MM-yyyy' : 'MM/dd/yyyy' }}{{'Homepage.TellUsOfWorkDescription2' | translate}}</p>
      </div>
      <button (click)="this.router.navigate(['profile'], { relativeTo: this.activatedRoute})" class="ontario-button ontario-button--primary ontario-button-hero">
        {{'Homepage.EditProfile' | translate}}
      </button>
    </div>
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt="placeholder"
        src="/assets/images/homepage-hazard.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text hero-text-hazard">
        <strong class="ontario-badge ontario-badge--neutral-light">{{this.completedHazards}} {{ 'Homepage.Of' | translate}} {{this.totalHazards}} {{'Homepage.HazardsCompleted' | translate}} </strong>
        <h2>{{'Homepage.ClarifyHazardTitle' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.ClarifyHazard1' | translate}} {{this.totalHazards}} {{'Homepage.ClarifyHazard2' | translate}}</p>
      </div>
      <button (click)="this.router.navigate(['hazards'], { relativeTo: this.activatedRoute})" class="ontario-button ontario-button--primary ontario-button-hero">
        {{'Homepage.ContinuePlanning' | translate}}
      </button>
    </div>
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt="placeholder"
        src="/assets/images/homepage-plan.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text hero-text-plan">
        <strong class="ontario-badge ontario-badge--neutral-light">{{'Homepage.YouHave1' | translate}} {{ this.totalSafetyPlans}} {{'Homepage.YouHave2' | translate}}</strong>
        <h2>{{'Homepage.PlanTitle' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.PlanDescriptionLoggedOn' | translate}}</p>
      </div>
      <button (click)="this.router.navigate(['safetyplans'], { relativeTo: this.activatedRoute})" class="ontario-button ontario-button--primary ontario-button-hero">
        {{'Homepage.DownloadSafetyPlan' | translate}}
      </button>
    </div>
  </div>
  <div class="ontario-row ontario-row-hero">
    <hr class="hero-underline" />
    <p>&nbsp;</p>
    <h2 class="additional-title">{{'Homepage.AdditionalServices' | translate}}</h2>
  </div>
  <div class="ontario-row">
    <ul class="ontario-card__container ontario-card--cards-per-row-2">
      <li (click)="this.router.navigate(['safetyboard'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/Health 1.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.HealthAndSafetyBoard' | translate }}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.HealthAndSafetyBoardDescription' | translate}}</p>
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['healthandsafetypartners'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/health-and-safety-partners.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.GetSupport' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.GetSupportDescription' | translate}}</p>
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['mentalhealth'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/mental-health-support.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.MentalHealth' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.MentalHealthDescription' | translate}}</p>
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['additionalsupport'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/additional-support.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.AdditionalSupport' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.AdditionalSupportDescription' | translate}}</p>
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['legalrequirements'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/Regulations 1.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.MyRegulations' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.MyRegulationsDescription' | translate}}</p>
          </div>
        </div>
      </li>
      
      <li (click)="this.router.navigate(['injuryreporting'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/Injury 1.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.ReportingAnInjury' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            @if (isWSIBClient == 'Yes') {
              <p>{{'HomepageCards.ReportingAnInjuryDescriptionWsib' | translate}}</p>
            } @else if (isWSIBClient == 'No') {
              <p>{{'HomepageCards.ReportingAnInjuryDescription' | translate}}</p>
            }
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['training'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/Records 1.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">
              {{'HomepageCards.TrainingRecords' | translate}}
            </a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.TrainingRecordsDescription' | translate}}</p>
          </div>
        </div>
      </li>
      <li (click)="this.router.navigate(['accountsettings'], { relativeTo: this.activatedRoute})"
        class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
        <div class="ontario-card__image-container">
          <img class="ontario-card__image"
            src="/assets/images/Workspace 1.svg" alt="">
        </div>
        <div class="ontario-card__text-container ontario-card--image-true">
          <h2 class="ontario-card__heading">
            <a href="javascript:{}">{{'HomepageCards.WorkspaceManagement' | translate}}</a>
          </h2>
          <div class="ontario-card__description">
            <p>{{'HomepageCards.WorkspaceManagementDescription' | translate}}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
} @else if (!this.isLoggedIn) {
<!-- NOT logged in -->
<div class="ontario-padding-bottom-0!">
  <div class="ontario-row ontario-row-hero">
    <div class="ontario-columns top-banner">
      <h1 class="title">
        {{'Homepage.Title' | translate}}
      </h1>
      <div class="lead-statement-contanier">
        <p class="ontario-lead-statement">{{'Homepage.TitleDescriptionLoggedOut' | translate}}</p>
      </div>
      <button class="ontario-button ontario-button--primary" (click)="login()">
        {{'Homepage.LoginRegister' | translate}}
      </button>
    </div>
  </div>
  <div class="ontario-row ontario-row-hero ontario-row-hero-grid">
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt=""
        src="/assets/images/homepage-workplace.png">
    </div>
    <div class="ontario-columns vertical-img-container">
      <img class="vertical-img" alt=""
        src="/assets/images/Icons_arrows_01.svg">
    </div>
    <div class="ontario-columns horizontal-img-container">
      <img class="horizontal-img" alt=""
        src="/assets/images/homepage-horizontal.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text">
        <h2>{{'Homepage.TellUsOfWorkTitle' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.LoggedOutWorkplaceDescription' | translate}}</p>
      </div>
    </div>
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt="placeholder"
        src="/assets/images/homepage-hazard.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text hero-text-hazard">
        <h2>{{'Homepage.LoggedOutPlanHeading' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.LoggedOutPlanDescription' | translate}}</p>
      </div>
    </div>
    <div class="ontario-columns hero-img-container">
      <img class="hero-img" alt="placeholder"
        src="/assets/images/homepage-plan.png">
    </div>
    <div class="ontario-columns hero-text-container">
      <div class="hero-text hero-text-plan">
        <h2>{{'Homepage.PlanTitle' | translate}}</h2>
        <p class="ontario-body-text">{{'Homepage.PlanDescription' | translate}}</p>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="ontario-columns bottom-text">
      <p innerHtml="{{'Homepage.PageDescription' | translate}}"></p><br><br>
      <!-- <div class="underline">
      </div> -->
      <h3>{{'Homepage.Disclaimer' | translate}}</h3>
      <p innerHtml=" {{'Homepage.DisclaimerSummary' | translate}}">

      </p>
    </div>
  </div>
</div>
}