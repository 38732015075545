<div class="ontario-form-group">
    <div class="closeButton">
        <button (click)="onBackClick()" class="backButton" aria-label="Back">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-chevron-left"></use>
            </svg>
        </button>
        <label for="text-feedback">
            <h5>{{'Feedback.' + data.category + 'Heading' | translate}}</h5>
        </label>
        <button (click)="onCloseClick()" aria-label="Close">
            <svg class="ontario-icon" alt="">
                <use href="#ontario-icon-close"></use>
            </svg>
        </button>
    </div>
    @if ((!data.feedback || data.feedback.trim() == '') && submitButtonClicked) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'Feedback.MessageBlankError' | translate}}
            </span>
        </div>    
    }
    @if (data.feedback && data.feedback.length >= 1000) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'Feedback.MessageTooLongError' | translate}}
            </span>
        </div>    
    }
    @if (!xssCheckPassed && checkedFeedback == data.feedback) {
        <div class="ontario-error-messaging" role="alert">
            <span class="ontario-error-messaging__icon">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-alert-error"></use>
                </svg>
            </span>
            <span class="ontario-error-messaging__content">
                {{'Feedback.MessageInvalidError' | translate}}
            </span>
        </div>    
    }
    <textarea class="ontario-input ontario-textarea" [class.ontario-input__error]="(!data.feedback || data.feedback.trim() == '') && submitButtonClicked || data.feedback && data.feedback.length >= 1000 || (!xssCheckPassed && checkedFeedback == data.feedback)" name="text-feedback" id="text-feedback" [(ngModel)]="data.feedback" cdkFocusInitial></textarea>
    <div class="ontario-row">
        <div class="ontario-columns ontario-small-12">
            <button (click)="submitClick()" class="ontario-button ontario-button--primary">
                {{'Feedback.SubmitFeedback' | translate}}
            </button>
            <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
                {{'Feedback.Cancel' | translate}}
            </button>
        </div>
    </div>
</div>

