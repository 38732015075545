<div id="warningDialog" class="session-confirm">
    <header>
      @if (!isError) {
        <h1 mat-dialog-title class="confirm-close">
          <div class="icon-background">
          </div>
          {{"General.AreYouThere?" | translate}}
        </h1>  
      } @else {
        <h1 mat-dialog-title class="confirm-close">
          {{"General.SessionExpired" | translate}}
        </h1>  
      }
      <hr/>
    </header>
    <mat-dialog-content>
      @if (!isError) {
        <div id="session-msg" class="prewrap-text"
        [innerHTML]="'General.YourSessionHasBeenInactiveForMinutesPlural' | translate : {mins: mins, inactive:inactive}"></div>
      } @else {
        <div id="session-end-msg" class="prewrap-text"
        [innerHTML]="'General.YourSessionHasExpiredDueToInactivity' | translate : {inactive:inactive}"></div>
      }
      <ng-container>
        <button id="dialog-cancel" class="btn ontario-button--primary ontario-button confirm-button" (click)="close()">
          {{"General.Ok" | translate}}
        </button>
      </ng-container>
    </mat-dialog-content>
  </div>
  