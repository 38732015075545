import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { BusinessModel } from 'src/app/models/business.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  constructor(private httpClient: HttpClient) { }
  private cachedBusiness: any = null;
  showProfileCompletedModal: boolean = false;

  createBusiness(): Observable<number> {
    return this.httpClient.post<number>(environment.apiBaseUrl + `business`, null);
  }

  getBusiness(): Observable<any> {
    if (this.cachedBusiness) {
      return of(this.cachedBusiness);
    } else {
      return this.httpClient.get<any>(environment.apiBaseUrl + 'business').pipe(
        tap(data => this.cachedBusiness = data),
        shareReplay(1)
      );
    }
  }

  invalidateCachedBusiness() {
    this.cachedBusiness = null;
  }

  getHomeDTO(): Observable<any> {
    return this.httpClient.get<any>(environment.apiBaseUrl + 'business/getHomeDTO')
  }

  updateSafetyPledge(businessName: string, pledgeManagementPerson: string, pledgeManagementRole: string): Observable<number> {
    this.invalidateCachedBusiness();
    return this.httpClient.patch<number>(environment.apiBaseUrl + 'business/updatesafetypledge', 
      {businessName, pledgeManagementPerson, pledgeManagementRole});
  }

  updateBusinessName(businessName: string): Observable<number> {
    this.invalidateCachedBusiness();
    return this.httpClient.patch<number>(environment.apiBaseUrl + 'business/updateBusinessName', {businessName: businessName});
  }

  getBusinessUsers(): Observable<{hasLoggedIn: boolean, userEmail: string}[]> {
    return this.httpClient.get<{hasLoggedIn: boolean, userEmail: string}[]>(environment.apiBaseUrl + 'business/getBusinessUsers');
  }

  getBusinessActivities(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseUrl}business/businessActivities`);
  }

  addUserToBusiness(userEmail: string): Observable<number> {
    return this.httpClient.post<number>(environment.apiBaseUrl + `business/addUserToBusiness`, {userEmail: userEmail});
  }

  removeUserFromBusiness(userEmail: string): Observable<number> {
    return this.httpClient.delete<number>(environment.apiBaseUrl + `business/removeUserFromBusiness`, {params: {userEmail: userEmail}});
  }
}
