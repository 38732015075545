<div class="ontario-form-group">
    <div class="header">
        @if (!data.currentUser) {
            <div class="centered-text">
                <h5>{{'WorkspaceManagement.RemoveWarning' | translate}}</h5>
            </div>    
        } @else {
            <div class="centered-text">
                <h5>{{'WorkspaceManagement.RemoveCurrentUserWarning' | translate}}</h5>
            </div>    
        }
        <div class="closeButton">
            <button (click)="onCloseClick()">
                <svg class="ontario-icon" alt="">
                    <use href="#ontario-icon-close"></use>
                </svg>
            </button>
        </div>
    </div>
    <button (click)="deleteUser()" class="ontario-button ontario-button--primary">
        {{'Buttons.Confirm' | translate}}
    </button>
    <button (click)="onCloseClick()" class="ontario-button ontario-button--secondary">
        {{'WorkspaceManagement.Cancel' | translate}}
    </button>
</div>