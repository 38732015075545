<app-header [title]="'Homepage.Title' | translate"></app-header>
@if ((this.authService.loggedIn$ | async) && !isHomePage) {
    <app-breadcrumbs></app-breadcrumbs>
}
<main id="main-content" class="container">
    <router-outlet></router-outlet>
</main>
<app-session-timeout></app-session-timeout>
@if(spinnerService.isLoadingSignal() == true) {
    <div class="loading-indicator" id="progress-container">
        <div class='ontario-loading-indicator__overlay' aria-hidden='false' role='alert' aria-live='assertive'>
            <div class='ontario-loading-indicator'>
                <svg class='ontario-loading-indicator__spinner' viewBox="25 25 50 50" xmlns='http://www.w3.org/2000/svg'>
                    <circle cx="50" cy="50" r="20" fill="none" stroke-width="4" />
                </svg>
                <p>{{'General.Loading' | translate}}</p>
            </div>
        </div>
    </div>    
}
<app-footer></app-footer>