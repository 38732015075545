<span class="hide-for-print breadcrumb-container" [ngStyle]="{'display': (breadcrumbsService.showBreadcrumbs$ | async) ? 'block' : 'none'}">
  <div id="block-breadcrumbs">
    <div class="row">
      <div class="column">
        <nav aria-label="Breadcrumb navigation">
          <ul class="breadcrumbs">
            @for(breadcrumb of this.breadcrumbsService.breadcrumbs$ | async; track breadcrumb; let last = $last) {
              @if(!last) {
                <li><a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a></li>
              } @else {
                <li>{{ breadcrumb.label }}</li>
              }
            }
          </ul>
        </nav>
      </div>
    </div>
  </div>
</span>
