<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                @if (validationFailed) {
                <div #errorHeading id="errorHeading" class="ontario-alert ontario-alert--error" tabindex="0">
                    <div class="ontario-alert__header">
                        <div class="ontario-alert__header-icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </div>
                        <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                    </div>
                    <div class="ontario-alert__body">
                        <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                        <ul>
                            @if (businessNameTooLong) {
                                <li><a href="javascript:{}" (click)="scrollToElement('businessNameLabel', 'businessName')">{{'HardcodedGlobals.FieldMaxLengthInvalid' | translate:{field: businessNameField, value: businessMaxLength} }}</a></li>
                            }
                            @if (businessNameInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('businessNameLabel', 'businessName')">{{'HardcodedGlobals.FieldInvalidError' | translate:{field: businessNameField} }}</a></li>
                            }
                            @if (otherSectorTooLong) {
                                <li><a href="javascript:{}" (click)="scrollToElement('otherSectorLabel', 'otherSector')">{{'HardcodedGlobals.FieldMaxLengthInvalid' | translate:{field: otherSectorField, value: otherSectorMaxLength} }}</a></li>
                            }
                            @if (otherSectorInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('otherSectorLabel', 'otherSector')">{{'HardcodedGlobals.FieldInvalidError' | translate:{field: otherSectorField} }}</a></li>
                            }
                            @if (businessSectorEmpty) {
                                <li><a href="javascript:{}" (click)="scrollToElement('businessSectorLabel', 'businessSector')">{{'HardcodedGlobals.OptionNotSelectedOrOther' | translate}}</a></li>
                            }
                            @if (numOfWorkersInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('numOfWorkersLabel', 'numOfWorkersRadio1')">{{'HardcodedGlobals.OptionNotSelectedFor' | translate}} {{'Step1.NumberOfWorkersField' | translate}}.</a></li>
                            }
                            @if (foreignWorkersInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('foreignWorkersLabel','foreignWorkersRadio1')">{{'HardcodedGlobals.OptionNotSelected' | translate}}</a></li>
                            }
                            @if (partTimeWorkersInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('partTimeWorkersLabel','partTimeWorkersRadio1')">{{'HardcodedGlobals.OptionNotSelected' | translate}}</a></li>
                            }
                            @if (under25Invalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('under25Label', 'under25Radio1')">{{'HardcodedGlobals.OptionNotSelected' | translate}}</a></li>
                            }
                            @if (wsibClientInvalid) {
                                <li *ngIf="wsibClientInvalid"><a href="javascript:{}" (click)="scrollToElement('wsibClientLabel','wsibClientRadio1')">{{'HardcodedGlobals.OptionNotSelected' | translate}}</a></li>
                            }
                            @if (dsrInvalid) {
                                <li *ngIf="dsrInvalid"><a href="javascript:{}" (click)="scrollToElement('dsrLabel', 'dsrRadio1')">{{'HardcodedGlobals.AtLeastOneOption' | translate:{field: dsrField.toLowerCase() + 's'} }}</a></li>
                            }
                            @if (dsrOptionsInvalid) {
                                <li><a href="javascript:{}" (click)="scrollToElement('dsrOptionsLabel','dsrOptionsCheck1')">{{'HardcodedGlobals.FieldAtLeastOne' | translate:{field: dsrField.toLowerCase()} }} </a></li>
                            } 
                        </ul>
                    </div>
                </div>
                }
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--without-back-button">
                            <span class="ontario-h4">{{'Stepper.Step' | translate}}&nbsp;1 {{'Stepper.Of' | translate}}&nbsp;3</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <h1 class="title">{{'Step1.Title' | translate}}</h1>
            <p class="lead">{{'Step1.LeadStatement' | translate}}</p>
        </div>
    </div>
    
    <div class="row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend></legend>
                    <label class="ontario-label" for="businessName" id="businessNameLabel">
                        {{'Step1.BusinessName' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Optional' | translate}})</span>
                    </label>
                    <div *ngIf="businessNameTooLong" class="ontario-error-messaging" id="field-error">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'HardcodedGlobals.FieldMaxLengthInvalid' | translate:{field: businessNameField, value: businessMaxLength} }}
                        </span>
                    </div>
                    <div *ngIf="businessNameInvalid" class="ontario-error-messaging" id="field-error">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'HardcodedGlobals.FieldInvalidError' | translate:{field: businessNameField} }}
                        </span>
                    </div>
                    <input class="ontario-input" type="text" id="businessName" [(ngModel)]="step1.BusinessName" [class.ontario-input__error]="businessNameInvalid || businessNameTooLong">
                    <div class="new-div ontario-hint-expander__container">
                        <button class="ontario-hint-expander__button" id="hint-expander-button-id-2" aria-controls="hint-expander-content" aria-expanded="false" data-toggle="ontario-collapse" (click)="toggleExpander($event)">
                            <span class="ontario-hint-expander__button-icon--close"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-up"></use>
                                </svg></span>
                            <span class="ontario-hint-expander__button-icon--open"><svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-down"></use>
                                </svg></span>
                            {{'Step1.BusinessNameHint' | translate}}
                        </button>
                        <div class="ontario-hint-expander__content" id="hint-expander-content-2" aria-labelledby="hint-expander-button-id-2" aria-hidden="true" data-toggle="ontario-expander-content">
                            <p innerHtml="{{'Step1.BusinessNameHintDescription' | translate}}"></p>
                        </div>
                    </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend></legend>
                <label class="ontario-label" for="businessSector" id="businessSectorLabel">
                    {{'Step1.BusinessSector' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                </label>
                <div *ngIf="businessSectorEmpty" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.OptionNotSelectedOrOther' | translate}}
                    </span>
                </div>
                <select class="ontario-input ontario-dropdown" id="businessSector" name="industry-sector" aria-label="industry-sector" aria-required="true" [(ngModel)]="step1.BusinessSector" [class.ontario-input__error]="businessSectorEmpty" (change)="toggleOptionalField()">
                    <option value="" selected>{{'HardcodedGlobals.AtLeastOne' | translate}}</option>
                    <option value="Agriculture">{{'Step1.Agriculture' | translate}}</option>
                    <option value="Construction">{{'Step1.Construction' | translate}}</option>
                    <option value="Education">{{'Step1.Education' | translate}}</option>
                    <option value="Fisheries">{{'Step1.Fisheries' | translate}}</option>
                    <option value="ForestryAndLogging">{{'Step1.ForestryAndLogging' | translate}}</option>
                    <option value="GovernmentAndRelatedServices">{{'Step1.GovernmentAndRelatedServices' | translate}}</option>
                    <option value="HospitalsHealthcareAndRelatedSocialServices">{{'Step1.HospitalsHealthcareAndRelatedSocialServices' | translate}}</option>
                    <option value="IndustrialServices">{{'Step1.IndustrialServices' | translate}}</option>
                    <option value="InformationAndTechnology">{{'Step1.InformationAndTechnology' | translate}}</option>
                    <option value="Landscaping">{{'Step1.Landscaping' | translate}}</option>
                    <option value="Manufacturing">{{'Step1.Manufacturing' | translate}}</option>
                    <option value="MiningQuarryingOilAndGasExtraction">{{'Step1.MiningQuarryingOilAndGasExtraction' | translate}}</option>
                    <option value="Performance">{{'Step1.Performance' | translate}}</option>
                    <option value="RestaurantAndFoodServices">{{'Step1.RestaurantAndFoodServices' | translate}}</option>
                    <option value="RetailAndWholesaleTrades">{{'Step1.RetailAndWholesaleTrades' | translate}}</option>
                    <option value="Tourism">{{'Step1.Tourism' | translate}}</option>
                    <option value="Transportation">{{'Step1.Transportation' | translate}}</option>
                    <option value="Utilities">{{'Step1.Utilities' | translate}}</option>
                    <option value="Automotive">{{'Step1.Automotive' | translate}}</option>
                    <option value="Warehousing">{{'Step1.Warehousing' | translate}}</option>
                    <option value="Other (please specify)">{{'Step1.Other' | translate}}</option>
                </select>
            </fieldset>
            <div *ngIf="step1.BusinessSector === 'Other (please specify)'">
                <label class="ontario-label" for="otherSector" id="otherSectorLabel">
                    {{'Step1.OtherSector' | translate}}
                </label>
                <div *ngIf="otherSectorTooLong" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.FieldMaxLengthInvalid' | translate:{field: otherSectorField, value: otherSectorMaxLength} }}
                    </span>
                </div>
                <div *ngIf="otherSectorInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.FieldInvalidError' | translate:{field: otherSectorField} }}
                    </span>
                </div>
                <input class="ontario-input" type="text" id="otherSector" [(ngModel)]="step1.OtherSector" [class.ontario-input__error]="otherSectorInvalid || otherSectorTooLong">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="numOfWorkersLabel">
                {{'Step1.NumberOfWorkers' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="numOfWorkersInvalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelectedFor' | translate}} {{'Step1.NumberOfWorkersField' | translate}}.
                </span>
            </div>
            <div class="ontario-radios">
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="numOfWorkersRadio1" name="radio-buttons" type="radio" value="Owner only" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="numOfWorkersRadio1">
                        {{'Step1.OwnerOnly' | translate}}
                    </label>
                </div>
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="radio-button-option-2" name="radio-buttons" type="radio" value="1 to 5" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="radio-button-option-2">
                        1 {{'HardcodedGlobals.To' | translate}} 5
                    </label>
                </div>
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="radio-button-option-3" name="radio-buttons" type="radio" value="6 to 19" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="radio-button-option-3">
                        6 {{'HardcodedGlobals.To' | translate}} 19
                    </label>
                </div>
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="radio-button-option-4" name="radio-buttons" type="radio" value="20 to 49" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="radio-button-option-4">
                        20 {{'HardcodedGlobals.To' | translate}} 49
                    </label>
                </div>
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="radio-button-option-5" name="radio-buttons" type="radio" value="50 to 99" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="radio-button-option-5">
                        50 {{'HardcodedGlobals.To' | translate}} 99
                    </label>
                </div>
                <div class="ontario-radios__item">
                    <input class="ontario-radios__input" id="radio-button-option-6" name="radio-buttons" type="radio" value="100+" [(ngModel)]="step1.NumOfWorkers">
                    <label class="ontario-label ontario-radios__label" for="radio-button-option-6">
                        {{'Step1.100Plus' | translate}}
                    </label>
                </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="foreignWorkersLabel">
                {{'Step1.ForeignWorkers' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="foreignWorkersInvalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}}
                </span>
            </div>
            <div class="ontario-radios horizontal">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="foreignWorkersRadio1" name="radio-buttonsp" type="radio" [value]="true" [(ngModel)]="step1.ForeignWorkers">
                        <label class="ontario-label ontario-radios__label" for="foreignWorkersRadio1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2p" name="radio-buttonsp" type="radio" [value]="false" [(ngModel)]="step1.ForeignWorkers">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2p">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="partTimeWorkersLabel">
                {{'Step1.PartTimeWorkers' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="partTimeWorkersInvalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}}
                </span>
            </div>
            <div class="ontario-radios horizontal">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="partTimeWorkersRadio1" name="radio-buttonsn" type="radio" [value]="true" [(ngModel)]="step1.PartTimeWorkers">
                        <label class="ontario-label ontario-radios__label" for="partTimeWorkersRadio1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2n" name="radio-buttonsn" type="radio" [value]="false" [(ngModel)]="step1.PartTimeWorkers">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2n">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="under25Label">
                {{'Step1.Under25' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="under25Invalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}}
                </span>
            </div>
            <div class="ontario-radios horizontal">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="under25Radio1" name="radio-buttonsz" type="radio" [value]="true" [(ngModel)]="step1.Under25">
                        <label class="ontario-label ontario-radios__label" for="under25Radio1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2z" name="radio-buttonsz" type="radio" [value]="false" [(ngModel)]="step1.Under25">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2z">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="wsibClientLabel">
                {{'Step1.WSIBClient' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend>
            <div *ngIf="wsibClientInvalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.OptionNotSelected' | translate}}
                </span>
            </div>
            <div class="ontario-radios">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="wsibClientRadio1" name="radio-buttonso" type="radio" value="Yes" [(ngModel)]="step1.IsWSIBClient">
                        <label class="ontario-label ontario-radios__label" for="wsibClientRadio1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2o" name="radio-buttonso" type="radio" value="No" [(ngModel)]="step1.IsWSIBClient">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2o">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-3o" name="radio-buttonso" type="radio" value="I don't know" [(ngModel)]="step1.IsWSIBClient">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-3o">
                            {{"HardcodedGlobals.Idon'tknow" | translate}}
                        </label>
                    </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
        <fieldset class="ontario-fieldset">
            <legend class="ontario-fieldset__legend" id="dsrLabel">
                {{'Step1.HasDesignatedSubstances' | translate}}
            </legend>
            <div class="dsrInfoLink">
                {{'Step1.DsrHintText' | translate}} 
                <a [href]="('Step1.DsrInfoLinkURL1' | translate)" target="_blank">{{'Step1.DsrInfoLinkText1' | translate}}</a>&nbsp;
                <a [href]="('Step1.DsrInfoLinkURL2' | translate)" target="_blank">{{'Step1.DsrInfoLinkText2' | translate}}</a>
            </div>
            <div *ngIf="dsrInvalid" class="ontario-error-messaging" id="field-error">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HardcodedGlobals.AtLeastOneOption' | translate:{field: dsrField.toLowerCase() + 's'} }}
                </span>
            </div>
            <div class="ontario-radios">
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="dsrRadio1" name="radio-buttonsh" type="radio" value="Yes" [(ngModel)]="step1.HasDesignatedSubstances">
                        <label class="ontario-label ontario-radios__label" for="dsrRadio1">
                            {{'HardcodedGlobals.Yes' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-2h" name="radio-buttonsh" type="radio" value="No" [(ngModel)]="step1.HasDesignatedSubstances">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-2h">
                            {{'HardcodedGlobals.No' | translate}}
                        </label>
                    </div>
                    <div class="ontario-radios__item">
                        <input class="ontario-radios__input" id="radio-button-option-3h" name="radio-buttonsh" type="radio" value="I don't know" [(ngModel)]="step1.HasDesignatedSubstances">
                        <label class="ontario-label ontario-radios__label" for="radio-button-option-3h">
                            {{"HardcodedGlobals.Idon'tknow" | translate}}
                        </label>                      
                    </div>
            </div>
        </fieldset>
        </div>
    </div>
    <div *ngIf="step1.HasDesignatedSubstances === 'Yes'" class="row">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <legend class="ontario-fieldset__legend" id="dsrOptionsLabel">
                    {{'Step1.SelectDesignatedSubstances' | translate}}<span class="ontario-label__flag">({{'HardcodedGlobals.Required' | translate}})</span>
                    <p>{{'HardcodedGlobals.SelectAll' | translate}}</p>
                </legend>                
                <div *ngIf="dsrOptionsInvalid" class="ontario-error-messaging" id="field-error">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HardcodedGlobals.FieldAtLeastOne' | translate:{field: dsrField.toLowerCase()} }}
                    </span>
                </div>
                <div class="ontario-checkboxes">
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="dsrOptionsCheck1" name="options" type="checkbox" value="option-1" [checked]="isChecked('Acrylonitrile')" (change)="onCheckboxChange('Acrylonitrile')">
                        <label class="ontario-checkboxes__label" for="dsrOptionsCheck1">{{'Step1.Acrylonitrile' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-2" name="options" type="checkbox" value="option-2" [checked]="isChecked('Arsenic')" (change)="onCheckboxChange('Arsenic')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-2">{{'Step1.Arsenic' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-3" name="options" type="checkbox" value="option-3" [checked]="isChecked('Asbestos')" (change)="onCheckboxChange('Asbestos')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-3">{{'Step1.Asbestos' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-4" name="options" type="checkbox" value="option-4" [checked]="isChecked('Benzene')" (change)="onCheckboxChange('Benzene')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-4">{{'Step1.Benzene' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-5" name="options" type="checkbox" value="option-5" [checked]="isChecked('Coke oven emissions')" (change)="onCheckboxChange('Coke oven emissions')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-5">{{'Step1.Cokeovenemissions' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-6" name="options" type="checkbox" value="option-6" [checked]="isChecked('Ethylene oxide')" (change)="onCheckboxChange('Ethylene oxide')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-6">{{'Step1.Ethyleneoxide' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-7" name="options" type="checkbox" value="option-7" [checked]="isChecked('Isocyanates')" (change)="onCheckboxChange('Isocyanates')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-7">{{'Step1.Isocyanates' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-8" name="options" type="checkbox" value="option-8" [checked]="isChecked('Lead')" (change)="onCheckboxChange('Lead')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-8">{{'Step1.Lead' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-9" name="options" type="checkbox" value="option-9" [checked]="isChecked('Mercury')" (change)="onCheckboxChange('Mercury')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-9">{{'Step1.Mercury' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-10" name="options" type="checkbox" value="option-10" [checked]="isChecked('Silica')" (change)="onCheckboxChange('Silica')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-10">{{'Step1.Silica' | translate}}</label>
                    </div>
                    <div class="ontario-checkboxes__item">
                        <input class="ontario-checkboxes__input" id="checkbox-option-11" name="options" type="checkbox" value="option-11" [checked]="isChecked('Vinyl chloride')" (change)="onCheckboxChange('Vinyl chloride')">
                        <label class="ontario-checkboxes__label" for="checkbox-option-11">{{'Step1.Vinylchloride' | translate}}</label>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <button class="ontario-button ontario-button--primary" (click)="saveAndContinue()">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>  
    </div>
  </div>