import { Component, inject } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SecurityContext } from '@angular/core';
import { BusinessService } from 'src/app/services/business/business.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-workspace-add-user-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule, TranslateModule],
  templateUrl: './workspace-add-user-dialog.component.html',
  styleUrls: ['./workspace-add-user-dialog.component.scss']
})
export class WorkplaceAddUserDialogComponent {
  data = inject(MAT_DIALOG_DATA);
  email : string = "";
  tooLongEmail: boolean = false;
  emptyEmail: boolean = false;
  userExist: boolean = false;
  xssCheckFailed: boolean = false;
  invalidEmail: boolean = false;
  isUserInOtherBusiness: boolean = false; 
  sanitizedEmail: string | null = "";


  constructor(public dialogRef: MatDialogRef<WorkplaceAddUserDialogComponent>, public dialog: MatDialog, private businessService: BusinessService, public translate: TranslateService, private sanitizer: DomSanitizer, private spinnerService: SpinnerService){}

  onCloseClick(): void {
    this.dialogRef.close();
  }
  
  submitClick() {
    this.emptyEmail = false;
    this.tooLongEmail = false;
    this.xssCheckFailed = false;
    this.invalidEmail = false;
    this.userExist = false;
    this.isUserInOtherBusiness = false;
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.sanitizedEmail = this.sanitizer.sanitize(SecurityContext.HTML, this.email);

    if (this.email.trim().length > 100)
      this.tooLongEmail = true;
    else if (this.sanitizedEmail !== this.email) 
      this.xssCheckFailed = true;
    else if (this.data.some((u: any) => u.userEmail == this.email.trim()))
      this.userExist = true;
    else if (this.email.trim() != '') {
      this.invalidEmail = !(emailRegex.test(this.email.trim()));
      if (!this.invalidEmail){
        this.businessService.addUserToBusiness(this.email.trim()).subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: (error) => {
            if(error.error?.Message === "Error from AntiXssMiddleware") {
              this.xssCheckFailed = true;
            }
            if(error.error?.message === "Validation Errors") {
              error.error.errors.forEach((e: { errorCode: string, errorMessage: string, propertyName: string; }) => {
                Object.assign(this, {[e.errorMessage]: true});
              });
            }
            this.spinnerService.hide();
          }
        });
      }
    }
    else 
      this.emptyEmail = true;
  }  
}
