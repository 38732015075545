<div>
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
            <!-- Error -->
            <div id="errorDiv" #errorDiv>
                <div *ngIf="validationFailed" class="ontario-alert ontario-alert--error">
                    <div class="ontario-alert__header">
                        <div class="ontario-alert__header-icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </div>
                        <h2 class="ontario-alert__header-title ontario-h4">{{'Error.ThereIsAProblem' | translate}}</h2>
                    </div>
                    <div class="ontario-alert__body">
                        <p>{{'Error.ErrorsWereFoundOnThisPage' | translate}}</p>
                        <ul>
                            <li *ngIf="controlEmptyError && section.type != 'Custom'"><a href="javascript:{}" (click)="scrollToHazardAnswer('suggestionsControllingHazardLabel')">{{'HazardTemplate.ControlEmptyError' | translate}}</a></li>
                            <li *ngIf="controlEmptyError && section.type == 'Custom'"><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.CustomControlEmptyError' | translate}}</a></li>
                            <li *ngIf="ownControlMeasuresTooLong"><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.MaxLengthInvalid' | translate:{value: 80} }}.</a></li>
                            <li *ngIf="xssCheckFailed"><a href="javascript:{}" (click)="scrollToHazardAnswer('ownControlMeasuresLabel')">{{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="ontario-step-indicator">
                <div class="ontario-row">
                    <div class="ontario-columns ontario-small-12">
                        <div class="ontario-step-indicator--with-back-button">
                            <button (click)="back()" class="ontario-button ontario-button--tertiary" >
                                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                    <use href="#ontario-icon-chevron-left"></use>
                                </svg>{{'Buttons.Back' | translate}}
                            </button>
                            <span class="ontario-h4">{{'Stepper.Step' | translate}}&nbsp;2 {{'Stepper.Of' | translate}}&nbsp;2</span>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>            
            <h1 class="title">
                {{translate.currentLang === 'en' ? businessModule.module.name : businessModule.module.nameFR }} - {{'HazardTemplate.Control' | translate}}
            </h1>            
            <div class="ontario-padding-bottom-24-!" innerHtml="{{translate.currentLang === 'en' ? section.summary : section.summaryFR}}"></div>
        </div>
    </div>
    <div class="ontario-row" *ngIf="section.type != 'Custom'">
        <div class="ontario-columns">
            <fieldset class="ontario-fieldset">
                <!-- <legend class="ontario-fieldset__legend" id="suggestionsControllingHazardLabel">
                    {{'HazardTemplate.SuggestionsControllingHazard' | translate}}
                </legend> -->
                <div *ngIf="controlEmptyError && section.type != 'Custom'" class="ontario-error-messaging" id="field-error" role="alert">
                    <span class="ontario-error-messaging__icon">
                        <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <use href="#ontario-icon-alert-error"></use>
                        </svg>
                    </span>
                    <span class="ontario-error-messaging__content">
                        {{'HazardTemplate.ControlEmptyError' | translate}}
                    </span>
                </div>
                <div class="ontario-checkboxes">
                    @for(cm of controlMeasures; track cm;) {
                        <div class="ontario-checkboxes__item">
                            <input class="ontario-checkboxes__input" id="checkbox-option-{{cm.priority}}" name="options" type="checkbox" value="cm.answerText" [checked]="selectedControlMeasuresIds.includes(cm.id)" (change)="onCheckboxChange(cm.id)">
                            <label class="ontario-checkboxes__label" for="checkbox-option-{{cm.priority}}">
                                <span innerHtml="{{translate.currentLang === 'en' ? cm.answerText : cm.answerTextFR}}"></span>
                            </label>
                        </div>    
                    }
                </div>
            </fieldset>
        </div>
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            
            <legend  class="ontario-fieldset__legend" id="ownControlMeasuresLabel" for="ownControlMeasures">
                {{'HazardTemplate.OwnControlMeasures' | translate}}<span class="ontario-label__flag" *ngIf="section.type == 'Custom'">({{'HardcodedGlobals.Required' | translate}})</span>
            </legend >
            <span>
                {{'HazardTemplate.OwnControlMeasuresSummary' | translate}}
            </span>
            <ng-template #maxFieldMessage>
                <p>{{ 'General.MaxFieldMessage' | translate}}</p>
            </ng-template>
            <div *ngIf="controlEmptyError && section.type == 'Custom'" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HazardTemplate.CustomControlEmptyError' | translate}}.
                </span>
            </div>
            <div *ngIf="xssCheckFailed" class="ontario-error-messaging" id="field-error" role="alert">
                <span class="ontario-error-messaging__icon">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-alert-error"></use>
                    </svg>
                </span>
                <span class="ontario-error-messaging__content">
                    {{'HazardTemplate.OwnControlMeasuresField' | translate}} {{'HardcodedGlobals.InvalidError' | translate}}
                </span>
            </div>
            @for(answer of ownControlMeasures; track answer; let i = $index) {
                <div class="input-row">
                    <label for="q1answer{{i}}" class="ontario-label sr-only">Label for Input {{i}}</label>
                    <div *ngIf="answer.validationFailed && ownControlMeasuresTooLong" class="ontario-error-messaging" id="field-error" role="alert">
                        <span class="ontario-error-messaging__icon">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use href="#ontario-icon-alert-error"></use>
                            </svg>
                        </span>
                        <span class="ontario-error-messaging__content">
                            {{'HazardTemplate.EntryTooLong' | translate}}
                        </span>
                    </div>
                    <div class="dynamic-input-container">
                        <input class="ontario-input" type="text" [(ngModel)]="answer.value" id="q1answer{{i}}" aria-label="Enter a value for question 1" [class.ontario-input__error]="answer.validationFailed || controlEmptyError || xssCheckFailed"/>
                        <button *ngIf="ownControlMeasures.length > 1" class="ontario-button ontario-button--tertiary" (click)="removeAnswer(i)">{{ 'Buttons.Delete' | translate}}</button>
                    </div>
                </div>
            }
            <button *ngIf="ownControlMeasures.length < 50; else maxFieldMessage" class="ontario-button ontario-button--tertiary" (click)="addAnswer()">
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="#ontario-icon-add"></use>
                </svg>{{ 'HazardTemplate.AddControlMeasure' | translate}}
            </button>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <button (click)="back()" class="ontario-button ontario-button--secondary">
                {{ 'Buttons.Back' | translate}}
            </button>
            <button (click)="saveAndContinue()" class="ontario-button ontario-button--primary">
                {{ 'Buttons.SaveAndContinue' | translate}}
            </button>
        </div>  
    </div>
  </div>