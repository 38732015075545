<div class="ontario-row">
    <div class="ontario-columns top-banner">
        <h1 class="title" >
            <p innerHtml="{{'ESP.Title' | translate}}"></p>
        </h1>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <p innerHtml="{{'ESP.Content1' | translate}}"></p>
        </div>
    </div>
    <div class="row">
        <div class="ontario-columns">
            <table>
                <tr>
                    <td><p innerHtml="{{'ESP.English' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Korean' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Tamil' | translate}}"></p></td>
                </tr>
                <tr>
                    <td><p innerHtml="{{'ESP.French' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Portuguese' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Thai' | translate}}"></p></td>
                </tr>
                <tr>
                    <td><p innerHtml="{{'ESP.Arabic' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Punjabi' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Ukrainian' | translate}}"></p></td>
                </tr>                
                <tr>
                    <td><p innerHtml="{{'ESP.Chinese (Traditional)' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Russian' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Urdu' | translate}}"></p></td>
                </tr>               
                <tr>
                    <td><p innerHtml="{{'ESP.Chinese (Simplified)' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Spanish' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Vietnamese' | translate}}"></p></td>
                </tr>                
                <tr>
                    <td><p innerHtml="{{'ESP.Hindi' | translate}}"></p></td>
                    <td><p innerHtml="{{'ESP.Tagalog' | translate}}"></p></td>
                    <td></td>
                </tr>               

            </table>
        </div>
      </div>
    
    <div class="row">
        <div class="ontario-columns">
            @if (EnPage) {
                <div class="img-container"><img src="/assets/images/esp_en.jpg" alt="Employment standards in Ontario poster preview" class="scaled-image"></div>
            }
            @if (FrPage) {
                <div class="img-container"><img src="/assets/images/esp_fr.jpg" alt="Affiche sur les normes d’emploi en Ontario – aperçu" class="scaled-image"></div>
            }
        </div>
    </div>
</div>
