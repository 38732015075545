<div class="ontario-padding-bottom-48-!">
    <div class="ontario-row">
        <div class="ontario-columns top-banner">
          <h1 class="title">
            {{'Profile.Heading' | translate}}
          </h1>          
          <p class="ontario-lead-statement">{{'Profile.Summary' | translate}}</p>
        </div>
    </div>
    <div class="ontario-row">
        <hr />
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            <h2 class="subheader">
                {{'Step1.Title' | translate}}
                <button class="ontario-button ontario-button--tertiary inline-button subheader-button" (click)="edit('step1')">
                    <svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                    </svg>{{ 'Buttons.Edit' | translate}}
                </button>
            </h2>
            <p class="ontario-label">{{ 'Step1.BusinessName' | translate }}</p>
            <p class="profile-details">{{ business.Step1.BusinessName }}</p>
            <p class="ontario-label">{{ 'Step1.BusinessSector' | translate }}</p>
            <p class="profile-details">{{ translate.instant("Step1." + business.Step1.BusinessSector) != "Step1." + business.Step1.BusinessSector ? translate.instant("Step1." + business.Step1.BusinessSector)  : business.Step1.BusinessSector }}</p>
            <p class="ontario-label">{{ 'Step1.NumberOfWorkers' | translate }}</p>
            <p class="profile-details">{{ business.Step1.NumOfWorkers }}</p>
            <p class="ontario-label">{{ 'Step1.ForeignWorkers' | translate }}</p>
            <p class="profile-details">{{ getYesNoValue(business.Step1.ForeignWorkers || false) }}</p>
            <p class="ontario-label">{{ 'Step1.PartTimeWorkers' | translate }}</p>
            <p class="profile-details">{{ getYesNoValue(business.Step1.PartTimeWorkers || false) }}</p>
            <p class="ontario-label">{{ 'Step1.Under25' | translate }}</p>
            <p class="profile-details">{{ getYesNoValue(business.Step1.Under25 || false) }}</p>
            <p class="ontario-label">{{ 'Step1.WSIBClient' | translate }}</p>
            <p class="profile-details">{{ "HardcodedGlobals." + business.Step1.IsWSIBClient |  translate }}</p>
            <p class="ontario-label">{{'Step1.HasDesignatedSubstances' | translate}}</p>
            <p class="profile-details">{{ "HardcodedGlobals." + business.Step1.HasDesignatedSubstances |  translate }}</p>
            @if (business.Step1.HasDesignatedSubstances === 'Yes') {
                <div>
                    <p class="ontario-label">{{'Step1.SelectDesignatedSubstances' | translate}}</p>
                    <p class="profile-details">{{ business.Step1.DesignatedSubstances }}</p>
                </div>    
            }
        </div>
    </div>
    <div class="ontario-row">
        <hr />
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            <h2 class="subheader">
                {{'Step2.Title' | translate}}
                <button class="ontario-button ontario-button--tertiary inline-button subheader-button" (click)="edit('step2')">
                    <svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                    </svg>{{ 'Buttons.Edit' | translate}}
                </button>
            </h2>
            <p class="ontario-label">{{'Step2.WhereDoYouOperate' | translate}}</p>
            <p class="profile-details">{{ business.Step2.Regions }}</p>
            <p class="ontario-label">{{'Step2.HeadOfficeLocation' | translate}}</p>
            <p class="profile-details">{{ business.Step2.HeadOfficeLocation != '' ? translate.instant("Step2." + business.Step2.HeadOfficeLocation) : '' }}</p>
            <p class="ontario-label">{{'Step2.MultipleLocations' | translate}}</p>
            <p class="profile-details">{{ getYesNoValue(business.Step2.MultipleLocations || false) }}</p>
        </div>
    </div>
    <div class="ontario-row">
        <hr />
    </div>
    <div class="ontario-row">
        <div class="ontario-columns">
            <h2 class="subheader">
                {{'Step3.Title' | translate}}
                <button class="ontario-button ontario-button--tertiary inline-button subheader-button" (click)="edit('step3')">
                    <svg class="ontario-icon" alt="" sol:category="interactions" aria-hidden="true" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-edit"></use>
                    </svg>{{ 'Buttons.Edit' | translate}}
                </button>
            </h2>
            <p class="ontario-label">{{'Step3.Q1' | translate}}</p>
            <p class="profile-details">{{ translate.currentLang === 'en' ? business.Activities : business.ActivitiesFR }}</p>
        </div>
    </div>
</div>
   